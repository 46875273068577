export const getStylesObject = () => ({
  container: {
    height: '97vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    background: '#F8F7FA',
    minHeight: 0,
  },
  noDataWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    backgroundColor: '#FFF',
  },
  tableWrapper: {
    overflowY: 'auto',
    height: '100vh',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '32px',
  },
  cancelButton: {
    marginRight: '8px',
  },
  flexSpacesBetweenRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  titleAndFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    alignItems: 'center',
  },
  filter: {
    backgroundColor: '#E8E7F0',
    borderRadius: '4px',
  }
});

export const variables = {
  filter: {
    buttonTextColor: '#1F0F66',
    toggleIndicatorColor: '#BCB7D2',
    textPadding: '6px 8px 6px 16px',
  }
}
